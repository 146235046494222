import React from 'react';

function ResourceAccessLoading () {
  return (
    <div className='resource-access-container'>
      <div className='access-placeholder-container'>
        <div className='placeholder placeholder-access placeholder-inline' />
        <div className='placeholder placeholder-inline' />
      </div>
    </div>
  );
}

export default ResourceAccessLoading;
